.vertical-navbar {
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    overflow-y: auto;
    padding: 20px 14px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 222px;
    z-index: 2;
    // Media Queries
    @media only screen and (max-width: 768px) {
        overflow-x: hidden;
        width: 100%;
    }

    * {
        transition: all 0.3s ease-in-out;
    }

    .navigation {
        display: flex;
        flex: 1;
        flex-direction: column;

        .back-nav {
            color: var(--dark-grey);
            display: flex;
            font-size: 11px;
            margin-bottom: 16px;

            p {
                opacity: 1;
            }

            img {
                height: 24px;
                margin-right: 5px;
            }
        }

        .nav-group {
            margin-bottom: 16px;

            .group-title {
                color: var(--black-60);
                display: flex;
                font-size: 11px;
                margin-bottom: 12px;
                overflow: hidden;
                padding: 0 14px;
                text-transform: uppercase;
                white-space: nowrap;
                width: 190px;
            }

            .nav-item {
                align-items: center;
                display: flex;
                justify-content: space-between;

                p {
                    opacity: 1;
                    overflow: hidden;
                    // width: 115px;
                }
            }
        }
    }

    .bottom-links {
        a {
            padding: 6px 14px;

            p {
                font-size: 12px;
                opacity: 1;
                overflow: hidden;

                &.upgrade-icon {
                    font-size: 18px;
                }
            }
        }
    }

    &.collapsed {
        overflow-x: hidden;
        width: 77px;

        .back-nav {
            img {
                height: 24px;
            }

            p {
                margin: 0;
                opacity: 0;
                visibility: hidden;
                width: 0;
            }
        }

        .bottom-links {
            p {
                height: 0;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                width: 0;

                &.visible {
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                    width: 100%;
                }
            }
        }

        .nav-group {
            .group-title {
                margin: 0;
                padding: 0;
                width: 0;
            }

            .nav-item {
                a {
                    height: 24px;
                }

                p {
                    margin: 0;
                    opacity: 0;
                    width: 0;
                }
            }
        }
    }
}
