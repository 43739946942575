.contact-list-container {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;

    .contact-list-name {
        color: var(--black-60);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .contact-list-position {
        color: var(--black-100);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 5px;
    }

    // .contact-list-contact {
    //     color: var(--primary-400);
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 600;
    //     line-height: 18px;
    // }
}
