.app {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &.collapsed {
        .body.private {
            margin-left: 88px;
            width: calc(100vw - 88px);
            // Media Queries
            @media only screen and (max-width:600px) {
                margin-left: 77px;
                width: calc(100vw - 77px);
            }
        }
    }
}

.body {
    flex: 1;

    img {
        -webkit-user-drag: none;
    }

    &.private {
        margin-left: 223px;
        overflow-y: auto;
        padding-top: 68px;
        transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
        width: calc(100vw - 223px);
        // Media Queries
        @media only screen and (max-width:600px) {
            margin-left: 0;
            width: 100%;
        }

        .header {
            align-items: center;
            background: var(--white);
            box-shadow: 0 1px 12px rgb(0 0 0 / 12%);
            display: flex;
            font-size: 18px;
            font-weight: 800;
            height: 80px;
            justify-content: center;
            letter-spacing: -0.41px;
            line-height: 22px;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .page {
            box-sizing: border-box;
            margin: auto;
            padding: 38px 24px 33px;

            .top-bar,
            .filter-bar {
                display: flex;
                justify-content: space-between;
                margin: 0 auto 30px;
                max-width: 1600px;
            }
            &.editor-base { padding-top: 0; }

            &.accounts {
                &.expanded {
                    margin: 0;
                }
            }
        }

        &:has(.editor-mobile) {
            display: flex;
            flex-direction: column-reverse;

            .editor-sidebar {
                border-left: none;
                width: 100%;
            }
        }
    }
}

.clickable {
    cursor: pointer;
    user-select: none;

    &:active {
        transform: scale3d(0.95, 0.95, 0.95);
    }
}

.blurred {
    filter: blur(5px);
}

// Media Queries
@media only screen and (max-width: 1400px) {
    .page {
        margin-right: 0 !important;
    }
}

@media only screen and (max-width: 500px) {
    .collapsed ~ .body.private {
        margin-left: 40px;
        width: calc(100vw - 40px);
    }

}
