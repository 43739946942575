.horizontal-navbar {
    .horizontal-navbar-item {
        align-items: center;
        display: flex;
        user-select: none;
    }

    .navbar-action {
        display: flex;
    }

    .action-block {
        align-items: center;
        display: flex;
        height: 100%;

        > div {
            .horizontal-navbar-item {
                margin-right: 25px;
            }
        }
    }

    .share-btn {
        background-color: var(--primary-400);
        border-radius: 5px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        padding: 9px 35px;
    }

    &.mobile-navbar {
        justify-content: space-between;
        top: 0;
        z-index: 4;
    }
}

.shared-view-container {
    display: grid;
    height: 100vh;
    overflow-y: auto;
    width: 100%;

    h1 {
        color: var(--black-300);
    }
}

.shared-view-content {
    display: flex;
    height: calc(100vh - 68px);
    margin: 0 auto;
    max-width: 1245px;
    width: 100%;
}

.shared-view-sidebar {
    @media only screen and (max-width: 1200px) {
        left: 0;
    }

    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 9px;
    height: 90%;
    left: calc(50vw - 615px);
    overflow-y: scroll;
    padding: 25px;
    position: absolute;
    top: 80px;
    width: 332px;

    p {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .selected-title {
        font-weight: bold;
    }
}

.text-container {
    color: var(--black-100);
    font-weight: 400;
    word-break: break-word;

    ul,
    ol {
        padding-inline-start: 40px;
    }

    h1 {
        font-size: 18px;
        font-weight: 600;
    }

    h2 {
        color: var(--black-300);
        font-size: 20px;
        font-weight: 600;
    }

    h3 {
        color: var(--black-300);
        font-size: 18px;
        font-weight: 600;
    }

    h4 {
        color: var(--black-300);
        font-size: 16px;
        font-weight: 600;
    }
}

.shelf-container {
    box-sizing: border-box;
    display: grid;
    gap: 15px 40px;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 10px 24px 10px 0;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 768px) {
        gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
        padding: 10px 15px 10px 10px;
    }
}

.table-container .table-header {
    align-items: center;
    background: var(--primary-400);
    color: var(--white);
    display: flex;
    justify-content: center;
    max-width: 400px;
    padding: 10px;
}

.table-rows {
    background: var(--white);
    border-color: var(--black-50);
    border-style: solid;
    border-width: 0 1px 1px;
    max-width: 400px;
    padding: 10px;
}

.embed-content-modal {
    .modal-wrapper {
        padding-bottom: 0 !important;
    }
}

.custom-modal {
    .modal-wrapper {
        display: flex;
        flex-direction: column;
        height: 90vh;
        min-width: 700px;
        padding-bottom: 0 !important;
        width: 50vw !important;

        @media only screen and (max-width: 768px) {
            margin: 0 10px;
            min-width: 100%;
        }
    }

    .modal-close {
        display: none !important;
    }

}
