.editor-base {
    background-color: var(--background-grey);
    height: calc(100vh - 124px);
    margin: 0 auto;
    max-width: 1400px;
    overflow: auto;

    &:has(.visible) {
        .top {
            z-index: 0; 
        } 

        .content {
            .editor-section {
                .section {
                    &.hidden {
                        opacity: 1;

                        .modal-wrapper {
                            position: relative;

                            &::after {
                                background-color: rgb(255 255 255 / 50%);
                                content: ' ';
                                height: 100%;
                                left: 0;
                                pointer-events: none;
                                position: absolute;
                                top: 0;
                                width: 100%;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    &:has(.visible-link-block) {
        .content {
            .editor-section {
                .section {
                    &.hidden {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .top {
        background-color: var(--background-grey);
        border-bottom: 1px solid var(--black-50);
        display: flex;
        height: 104px;
        padding: 38px 0 29px;
        position: sticky;
        top: 0;
        z-index: 3;
        // Media Queries
        @media only screen and (max-width: 1000px) {
            display: flex;
            flex-direction: column;
            height: auto;

            .button {
                margin: 0;
                width: auto;

                &.publish-button {
                    margin-bottom: 10px;
                }
            }
        }
    }


    .content {
        background: #fff;
        border-radius: 8px;
        margin-top: 20px;
        padding: 36px;

        &>input,
        .section-title>input {
            background-color: transparent;
            border: none;
            border-radius: 8px;
            display: flex;
            padding: 5px 10px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: var(--input-grey);
            }

            &:focus {
                background-color: var(--black-50);
                outline: none;
            }

            &::placeholder {
                color: var(--black-75);
            }

            &.editor-name {
                font-size: 28px;
                line-height: 42px;
                margin-bottom: 28px;
                width: 100%;
            }
        }

        .editor-section {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            .section {
                margin-top: 20px;

                &.hidden {
                    // .section-body-content {
                    opacity: 0.5;
                    // }

                }

                .section-title {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    input {
                        font-size: 22px;
                        line-height: 32px;
                        width: calc(100% - 140px);
                    }
                }

                .block-wrapper {
                    align-items: center;
                    background-color: var(--background-grey);
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 30px;
                    padding: 35px 0;
                    transition: all ease 0.3s;

                    &:hover {
                        background-color: var(--border-color);
                        box-shadow: 0 2px 3px 0 rgb(0 0 0 / 24%),
                            0 5px 17px 0 rgb(0 0 0 / 19%);
                        cursor: pointer;
                    }

                    img {
                        margin-right: 8px;
                    }

                    p {
                        color: var(--black-75);
                    }
                }

                .editor-block {
                    background: #fff;
                    border-radius: 8px;
                    margin-bottom: 30px;
                }

                .new-content-block-wrapper {
                    background-color: var(--background-grey);
                    border: 1px dashed rgb(170 170 170 / 30%);
                    border-radius: 0 0 8px 8px;
                    border-top: none;

                    .new-content-block-button {
                        align-items: center;
                        border-radius: 0 0 8px 8px;
                        display: flex;
                        height: 48px;
                        opacity: 0.4;
                        padding: 15px  24px;
                        transition: all ease 0.3s;

                        img {
                            margin-right: 8px;
                        }

                        img:nth-child(2) {
                            display: none;
                        }

                        &:hover {
                            img:nth-child(1) {
                                display: none;
                            }

                            img:nth-child(2) {
                                display: block;
                            }
                        }
                    }

                }

                &:hover {
                    .section-title {
                        input {
                            opacity: 1;
                        }
                    }

                    .section-body {
                        background-color: var(--background-grey);
                        border: 1px solid var(--background-grey);
                        border-bottom: 1px solid white;
                    }

                    .new-content-block-wrapper {
                        border: 1px solid var(--background-grey);
                        border-top: none;
                    }

                    .new-content-block-button {
                        opacity: 1;
                    }
                }
            }
        }

        .action-block {
            align-items: center;
            display: flex;
            justify-content: flex-end;

            button {
                background-color: var(--black-20);
                border: none;
                border-radius: 20px;
                color: var(--black-250);
            }
        }
    }
}
