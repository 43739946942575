.horizontal-navbar {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid var(--black-50);
    display: flex;
    height: 68px;
    justify-content: space-between;
    padding: 0 24px;
    position: fixed;
    width: 100%;
    z-index: 3;


    .navbar-action {
        display: flex;

        #navbar-profile-image {
            border-radius: 50px;
            height: 40px;
            object-fit: cover;
            width: 40px;
        }

        .notification {
            margin-right: 18px;
        }
    }
}
