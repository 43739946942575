/* stylelint-disable no-descending-specificity */
.accounts {
    transition: all 0.3s  ease-in-out;
    width: 100%;

    &.expanded {
        width: calc(100% - 380px);
    }

    .top-bar {
        /* Media Queries */

        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    .accounts-action {
        align-items: center;
        display: flex;

        /* Media Queries */
        @media only screen and (max-width: 800px) {
            justify-content: space-between;
            margin-top: 20px;
        }

        .input-container {
            &::after {
                background: var(--black-20);
            }
        }

        .button {
            margin-left: 36px;
        }
    }
}

.all-accounts-page {
    /* Media Queries */
    @media only screen and (max-width: 900px) {
        overflow-x: auto;
    }

    h1 {
        margin-bottom: 16px;
    }

    p {
        &.all-accounts-info {
            color: var(--text-medium);
            margin-bottom: 56px;
            width: 50%;
        }
    }

    .action-block {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
            min-width: auto;
            padding: 0;
        }
    }

    .modal-close {
        border-bottom: 1px solid #eaeaea;
        font-size: small;
        height: 10px;

        button {
            left: 20px;
            position: relative;
            top: 20px;
            transform: translate(-80%, -45%);
        }

        :only-child {
            left: 20px;
            position: relative;
            top: 20px;
            transform: translate(-80%, -45%);
        }
    }

    .accounts-modal-header {
        margin-bottom: 30px;
    }

    .accounts-modal-button {
        margin-top: 30px;
    }

    .page.accounts.expanded {
        margin-right: 345px !important;
        transition: margin-right 0.6s ease-in-out !important;
    }
}

.accounts-modal {
    .modal-wrapper {
        display: flex;
        flex-direction: column;

        h3 {
            margin-bottom: 30px;
        }

        .input {
            margin: 10px 0;
        }

        .button {
            margin-top: 30px;
        }
    }

    .company-logo-image-wrapper {
        display: flex;
        justify-content: center;
        margin: 20px auto;
        position: relative;
        width: fit-content;

        .logo-image {
            border-radius: 50px;
            height: 65px;
            object-fit: cover;
            width: 65px;
        }

        .close-icon {
            background-color: var(--background-grey);
            border: 1px solid var(--black-50);
            border-radius: 20px;
            box-shadow: var(--box-shadow2);
            box-sizing: border-box;
            cursor: pointer;
            left: 6px;
            opacity: 0;
            padding: 3px;
            position: absolute;
            top: 6px;
            transition: all 0.3s ease-in-out;
            width: 20px;

            &:hover {
                box-shadow: var(--box-shadow);
            }
        }

        &:hover {
            .close-icon {
                opacity: 1;
            }
        }
    }
}

.tabs {
    display: flex;
    margin: auto;
    max-width: 1600px;
}
