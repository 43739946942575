.shared-view-vertical-navbar {
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 15px;
    padding-top: 0;
    padding-top: 70px;
    position: absolute;
    scroll-margin-top: 70px;
    transform: translate(0);
    transition: transform 0.4s ease-in-out;
    width: 100%;
    z-index: 3;

    &.collapsed-mobile {
        transform: translate(-100%);
    }
}

.action-block {
    @media only screen and (max-width: 768px) {
        display: flex;
        height: 40px;
        justify-content: space-between;
        margin: 15px 0 30px;
    }

    .share-btn {
        background-color: var(--primary-400);
        border-radius: 5px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        padding: 9px 35px;
    }
}
