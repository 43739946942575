/* stylelint-disable no-descending-specificity */
.all-workspace-page {
    transform: translate3d(0, 0, 0);
    transition: transform 0.7s ease-in-out;

    /* Media Queries */
    @media only screen and (max-width: 900px) {
        overflow-x: auto;
    }

    h1 {
        margin-bottom: 16px;
    }

    p {
        &.all-workspace-info {
            color: var(--text-medium);
            margin-bottom: 56px;
            width: 50%;
        }
    }

    .action-block {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
            min-width: auto;
            padding: 0;
        }
    }
}

.sidebar.active ~ .all-workspace-page {
    transform: translate3d(-120px, 0, 0);
}
